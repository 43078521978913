<script>
import { ref } from 'vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import DrawerModal from '/~/components/drawer/components/drawer-modal.vue'

export default {
  name: 'drawer-confirmation-modal',
  components: {
    BaseButton,
    BaseIcon,
    DrawerModal,
  },
  props: {
    type: {
      type: String,
      default: 'error',
      validator: (v) => /success|warning|error/.test(v),
    },
    icon: {
      type: String,
      default: null,
    },
    iconSize: {
      type: Number,
      default: 60,
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    onConfirm: {
      type: Function,
      default: null,
    },
    onCancel: {
      type: Function,
      default: null,
    },
  },
  setup(props) {
    const isVisible = ref(false)
    const isConfirming = ref(false)

    function show() {
      isVisible.value = true
    }

    function hide() {
      isVisible.value = false
    }

    async function onConfirmClicked() {
      if (props.onConfirm) {
        isConfirming.value = true

        await props.onConfirm()

        isConfirming.value = false
      }

      hide()
    }

    async function onCancelClicked() {
      if (props.onCancel) {
        await props.onCancel()
      }

      hide()
    }

    return {
      isVisible,
      isConfirming,
      show,
      hide,
      onConfirmClicked,
      onCancelClicked,
    }
  },
}
</script>

<template>
  <drawer-modal to="menu-modal" :visible="isVisible" @hide="hide">
    <div class="pt-5">
      <div
        class="mb-[30px] flex h-72 items-center justify-center bg-center bg-no-repeat pt-2.5"
        :style="{
          backgroundImage: `url('/images/drawer/confirmation-modal/header-bg-${type}.svg')`,
        }"
      >
        <div
          class="flex h-[120px] w-[120px] items-center justify-center rounded-full text-white"
          :class="{
            'bg-orange-700': type === 'warning',
            'bg-red-700': type === 'error',
            'bg-emerald-700': type === 'success',
          }"
        >
          <slot name="icon">
            <base-icon :svg="icon" :size="iconSize" />
          </slot>
        </div>
      </div>
      <slot name="title">
        <div
          class="mb-4 text-center text-2xl font-bold leading-8 text-eonx-neutral-800"
        >
          {{ title }}
        </div>
      </slot>
      <slot name="text">
        <div
          class="mb-4 text-center text-base font-normal leading-6 text-eonx-neutral-600"
        >
          {{ text }}
        </div>
      </slot>
      <div class="mt-[30px] pb-5">
        <base-button
          :full-width="true"
          :loading="isConfirming"
          :disabled="isConfirming"
          @click="onConfirmClicked"
        >
          <slot name="confirmButtonText">Confirm</slot>
        </base-button>
        <button
          v-if="showCancelButton"
          type="button"
          :disabled="isConfirming"
          class="w-full py-4 text-center font-bold text-primary disabled:text-eonx-neutral-600"
          @click="onCancelClicked"
        >
          <slot name="cancelButtonText">Cancel</slot>
        </button>
      </div>
    </div>
  </drawer-modal>
</template>
